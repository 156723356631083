<template>
  <div class="beribit-container">
    <div class="currency-btn">
      <el-radio-group v-model="currencyRadio" @change="changeReportLevel">
        <el-radio-button label="BTC"></el-radio-button>
        <el-radio-button label="ETH"></el-radio-button>
      </el-radio-group>
    </div>
    <div class="user-tabs">
      <el-tabs
        class="user-data-tabs"
        v-model="activeName"
        @tab-click="handleClick"
      >
        <!-- <el-tab-pane label="持仓数据" name="first">
          <keep-alive>
            <PositionData
              name="first"
              :radio="currencyRadio"
              :activeNameTab="activeName"
              v-if="activeName == 'first'"
            />
          </keep-alive>
        </el-tab-pane> -->
        <el-tab-pane label="成交数据" name="first">
          <keep-alive>
            <BCompositeNumber
              name="first"
              :radio="currencyRadio"
              :activeNameTab="activeName"
              v-if="activeName == 'first'" /></keep-alive
        ></el-tab-pane>
        <el-tab-pane label="波动率" name="second">
          <keep-alive
            ><BWaveRate
              name="second"
              :radio="currencyRadio"
              :activeNameTab="activeName"
              v-if="activeName == 'second'" /></keep-alive
        ></el-tab-pane>
        <!-- <el-tab-pane label="期权标的" name="fourth">
          <keep-alive
            ><EpochMaking
              name="fourth"
              :radio="currencyRadio"
              :activeNameTab="activeName"
              v-if="activeName == 'fourth'" /></keep-alive
        ></el-tab-pane> -->
        <el-tab-pane label="其他数据" name="third">
          <keep-alive
            ><BOtherNumbers
              name="third"
              :radio="currencyRadio"
              :activeNameTab="activeName"
              v-if="activeName == 'third'" /></keep-alive
        ></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import BCompositeNumber from "./Bybit/bybitCompositeNumber.vue";
import BOtherNumbers from "./Bybit/bybitOtherNumbers.vue";
import PositionData from "./option-data/positionData.vue";
import BWaveRate from "./Bybit/bybitWaveRate.vue";
import EpochMaking from "./option-data/epochMaking.vue";
import DeribitOptionUnderlying from "./deribitOptionUnderlying.vue";
export default {
  components: {
    BCompositeNumber,
    BOtherNumbers,
    PositionData,
    BWaveRate,
    EpochMaking,
    DeribitOptionUnderlying,
  },
  data() {
    return {
      currencyRadio: "BTC",
      activeName: "first",
    };
  },
  watch: {
    // radio: {
    //   handler(newValue) {
    //     this.currencyRadio = newValue;
    //   },
    // },
  },
  created() {
    // 进入页面时候获取来判断路由type不同交易所请求不同接口数据
  },
  computed: {},
  mounted() {},
  methods: {
    handleClick(tab, event) {
      //   console.log(tab.name);
      //  this.activeName = tab.name;
    },
    changeReportLevel(item) {
      this.currencyRadio = item;
    },
  },
};
</script>
<style lang="scss" >
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: transparent !important;
  // border-color: transparent !important;
}
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  /* -webkit-box-shadow: -1px 0 0 0 #409EFF; */
  box-shadow: none !important;
}
.beribit-container {
  .currency-btn {
    width: 100%;
    border-bottom: 1px solid rgba(239, 195, 148, 0.3);
    .el-radio-group {
      .el-radio-button {
        margin: 0 32px 0 0;
        .el-radio-button__inner {
          background: transparent !important;
          border: none;
          height: 44px;
          line-height: 44px;
          padding: 0 !important;
          font-size: 20px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #d1d4dc;
          border-radius: 0 !important;
        }
        .el-radio-button__inner:focus {
          border-bottom: 2px solid #edbd88;
          color: #edbd88;
        }
        .el-radio-button__orig-radio:checked + .el-radio-button__inner {
          border-bottom: 2px solid #edbd88;
          color: #edbd88;
        }
      }
    }
  }
  .user-tabs {
    margin-top: 23px;
    .user-data-tabs {
      .el-tabs__header {
        margin: 0 !important;
        .el-tabs__nav-wrap {
          .el-tabs__nav-scroll {
            .el-tabs__nav {
              .el-tabs__active-bar {
                background-color: #edbd88;
                height: 1px;
              }
              .el-tabs__item.is-active {
                background: transparent !important;
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #edbd88;
              }
              .el-tabs__item {
                width: fit-content;
                background: transparent !important;
                height: 20px;
                line-height: 20px;
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #d1d4dc;
                // padding-bottom: 12px;
                margin: 0 !important;
                padding: 0 40px 0 0 !important;
              }
            }
          }
        }
        .el-tabs__nav-wrap::after {
          display: none !important;
        }
      }
      .el-tabs__content {
        margin: 24px 0 60px;
        .el-tab-pane {
        }
      }
    }
  }
}
</style>